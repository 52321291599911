import { Link } from '@lims-common-ux/lux';

export interface AddOn {
  testId: string;
}

export type CancelReasonCode = 'CUSTOMER_REQUEST' | 'IDEXX_ERROR';

export class CancelReason {
  constructor(
    cancelReason: CancelReason,
    public code: CancelReasonCode = cancelReason.code,
    public description: string = cancelReason.description
  ) {}
}

export class CanceledTest {
  constructor(
    test: Test,
    public testId: string = test.testId,
    public cancelReasonCode: CancelReasonCode = test.cancelReasonCode
  ) {}
}

export interface TestCodeSearchResponse {
  _embedded?: {
    testSearchResources: Test[];
  };
  _links: Link;
}

export interface AssayWithParent {
  assay: Test;
  panel?: Test;
  profile?: Test;
}

export enum TestType {
  'PROFILE' = 'PROFILE',
  'PANEL' = 'PANEL',
  'ASSAY' = 'ASSAY',
}

export enum DisplayType {
  'VALIDATE_MANUALLY' = 'leopard-box',
  'BLOCKED' = 'alert',
  'POSITIVE' = 'positive',
}

export enum ReviewDisplayType {
  'NONE' = '',
  'REVIEW_ALERT' = 'review-alert',
  'REVIEW_POSITIVE' = 'review-positive',
  'REVIEW_ALERT_POSITIVE' = 'review-alert review-positive',
}

export enum DescendentQuantity {
  'NONE' = 'NONE',
  'SOME' = 'SOME',
  'ALL' = 'ALL',
}

export class Test {
  constructor(
    test: Test,
    public testId: string = test.testId,
    public name: string = test.name,
    public testCode: string = test.testCode,
    public label: string = `${testCode} - ${name}`,
    public assays: Test[] = test.assays ? test.assays : ([] as Test[]),
    public panels: Test[] = test.panels ? test.panels : ([] as Test[]),
    public displayType: DisplayType = test.displayType || Test.resolveItemDisplayType(test),
    public reviewDisplayType: ReviewDisplayType = test.reviewDisplayType
      ? test.reviewDisplayType
      : ReviewDisplayType.NONE,
    public contextualizedTestId: string = test.contextualizedTestId || test.testId,
    public resolved: boolean = test.resolved || false,
    public reviewAlert: boolean = test.reviewAlert || false,
    public reviewPositive: boolean = test.reviewPositive || false,
    public isAddOn: boolean = test.isAddOn || false,
    public isUncanceled: boolean = test.isUncanceled || false,
    public cancelReasonCode: CancelReasonCode = test.cancelReasonCode || null,
    public testType: TestType = test.testType,
    readonly doNotRun: boolean = test.doNotRun || false,
    public _links: {
      self: {
        href: string;
      };
    } = test._links ? test._links : null
  ) {}

  setIsAddOn(value: boolean) {
    this.isAddOn = !!value;
  }

  setIsUncanceled() {
    this.isUncanceled = true;
  }

  updateCancelReason(code?) {
    const cancelReasonCode = code;
    if (cancelReasonCode) {
      this.cancelReasonCode = cancelReasonCode;
    } else {
      this.cancelReasonCode = null;
    }
  }

  getDescendants(): Test[] {
    const descendants: Test[] = [];
    this.panels?.forEach((panel) => {
      descendants.push(panel);

      panel.assays?.forEach((assay) => {
        descendants.push(assay);
      });
    });
    this.assays?.forEach((assay) => {
      descendants.push(assay);
    });
    return descendants;
  }

  // Given a test and a DisplayType, determine if NONE, SOME, or ALL of the test's descendents match the DisplayType
  quantifyDescendents(qualificationType: DisplayType): DescendentQuantity {
    const descendents = this.getDescendants();
    let descendentQuantification = DescendentQuantity.NONE;

    const qualifiedDescendents = [];

    descendents.forEach((test) => {
      if (test.displayType === qualificationType) {
        qualifiedDescendents.push(test.contextualizedTestId);
      }
    });

    if (descendents.length > 0 && descendents.length === qualifiedDescendents.length) {
      descendentQuantification = DescendentQuantity.ALL;
    } else if (qualifiedDescendents.length === 0) {
      descendentQuantification = DescendentQuantity.NONE;
    } else if (descendents.length - qualifiedDescendents.length > 0) {
      descendentQuantification = DescendentQuantity.SOME;
    } else {
      throw new Error('Descendent calculation error');
    }

    return descendentQuantification;
  }

  static createTest(test: Test) {
    test.panels = test.panels?.map((panel) => {
      panel.assays = panel.assays.map((assay) => {
        return new Test(assay);
      });

      return new Test(panel);
    });

    test.assays = test.assays?.map((assay) => {
      return new Test(assay);
    });

    return new Test(test);
  }

  static resolveItemDisplayType(test: Test) {
    let displayType = test.displayType;

    if (!displayType) {
      displayType = test.doNotRun ? DisplayType.BLOCKED : DisplayType.VALIDATE_MANUALLY;
    }

    return displayType;
  }
}
